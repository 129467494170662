import { defineStore } from 'pinia'
import type {
  Attachment,
  Jobsite,
  LineItem,
  Order,
  Vendor,
  User
} from '@quotetome/materials-api'

interface State {
  attachment: any | null,
  bom: any,
  bomOrder: { jobsite: Jobsite, items: LineItem[], supplier?: Vendor } | null | Order,
  csdOrder: Order | null,
  orderFromEmail: { unassociated_email: number, owner: User, quote_attachment: Attachment } | null,
  requisition: any | null
}

export const useOrdersStore = defineStore('orders', {
  state: (): State => ({
    attachment: null,
    bom: null,
    bomOrder: null,
    csdOrder: null,
    orderFromEmail: null,
    requisition: null
  }),
  actions: {
    setAttachment(attachment: any) {
      this.attachment = attachment
    },
    setBom(bom: any) {
      this.bom = bom
    },
    setBomOrder(bomOrder: State['bomOrder']) {
      this.bomOrder = bomOrder
    },
    setCsdOrder(csdOrder: Order | null) {
      this.csdOrder = csdOrder
    },
    setOrderFromEmail(orderFromEmail: State['orderFromEmail']) {
      this.orderFromEmail = orderFromEmail
    },
    setRequisition(requisition: any) {
      this.requisition = requisition
    },
  }
})

export default useOrdersStore
